import React from "react";

class PhiladelphiaArticle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    async componentDidMount() {      
                
    }

    render() {            
        return (            
            <div>       
                <h1> Top 5 must do things in Philadelphia for Lunar New Years celebration </h1>
                <ul>
                    <li> Make handmade dumpling at dumpling academy - January 29 - Febuary 19 @ Dumpling Academy </li>
                </ul>
        </div>)
    }
}

export default PhiladelphiaArticle