import React from "react";

class NYCCafes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div>
                <h1> NYC's most unique cafes </h1>
                <li> Birch Coffee </li>
                <li> Butler </li>
                <li> Devocion </li>
                <li> Felix Roasting Co </li>
                <li> Kobrick Coffee </li>
                <li> Maman </li>
                <li> Roseecrans </li>
                <li> Semicolen </li>
                <li> Supermoon Bakehouse </li>
            </div>)
    }
}

export default NYCCafes