import React from "react";

class NYCArticle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {            
        return (            
            <div>
                <h1> NYC Hot pot guide </h1>
                <li> 99 Favor Taste - New York </li>
                <li> Da Yu Hot Pot </li>
                <li> Da Long Yi Hot Pot </li>
                <li> Er hot pot </li>
                <li> Haidilao Hotpot Flushing </li>
                <li> Hometown Hotpot & BBQ </li>                
                <li> Hou Yi Hot Pot </li>
                <li> Laojie Hotpot </li>
                <li> Liuyishou Hotpot </li>
                <li> The Dolar Shop </li>
                <li> Xiang Hotpot-Brooklyn </li>
        </div>)
    }
}

export default NYCArticle