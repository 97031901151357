import React from "react";

import {
    Link,
} from "react-router-dom";

class Seattle extends React.Component {

    render() {
        return (<div class='container'>
            <h1> Seattle the Emerald City guide </h1>
        </div>)
    }
}

export default Seattle