import React from "react";

class NYCAYCEBBQArticle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {            
        return (            
            <div>       
                <h1> NYC all you can eat BBQ </h1>
                <h2> Manhattan </h2>
                <ul>
                    <li> Let's Meat | Lunch $26.99 - $29.99 | Dinner $39.99 - $45.99 </li>
                    <li> Hometown hotpot and bbq | BBQ $35.99 </li>
                </ul>
                <h2> Queens </h2>
                <li> 99 Favor taste | $29 </li> 

        </div>)
    }
}

export default NYCAYCEBBQArticle