import React, { useState } from 'react';

import Sidebar from './sidebar'

function Itinerary() {
  return (
    <div>      
        <Sidebar/>
    </div>
  );
}

export default Itinerary