import './Speakeasy.css';

function Speakeasy() {
    return (
        <div>        
            <h1> Top New York Speakeasies </h1>
            <ul>
                <li> Chef table </li>
                <li> Peachy's </li>
                <li> The Hidden Pearl </li>
                <li> Angel's Share </li>
            </ul>
         </div>
    )
}

export default Speakeasy