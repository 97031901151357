import React from "react";

class Donate extends React.Component {

    render() {
        return (<div>
            <h1> Donate to help support site costs </h1>
            <p> Venmo: @micklebrain </p>
            <p> https://www.patreon.com/micklebrain </p>
            <script type='text/javascript' src='https://ko-fi.com/widgets/widget_2.js'></script><script type='text/javascript'>kofiwidget2.init('Support Me on Ko-fi', '#29abe0', 'T6T41ICVW');kofiwidget2.draw();</script>
        </div>)
    }
}

export default Donate