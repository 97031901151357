function booking() {
    
    return (
        <div>        
            <h1> Booking </h1>
            <li>Monday</li>
            <li>Tuesday</li>
            <li>Wednesday</li>
            <li>Thursday</li>
            <li>Friday</li>
            <li>Saturday</li>
            <li>Sunday</li>            
         </div>
    )
}

export default booking