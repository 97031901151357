import React from "react";

class NYCArtGalleriesArticle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {            
        return (            
            <div>
                <h1> Art galleries to buy some of the finest art </h1>
                <li> David Zwirner </li>
                <li> Gagosian Gallery </li>
                <li> Hauser & Wirth New York </li>
                <li> West Chelsea Contemporary </li>                
        </div>)
    }
}

export default NYCArtGalleriesArticle