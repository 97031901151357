import React from "react";

class NYCBobaTeaArticle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {            
        return (            
            <div>
                <h1> NYC Boba Tea guide </h1>
                <ul>                                        
                    <li> Golden Diner </li>
                    <li> Jack's Wife Freda </li>
                    <li> Leland Eating and Drinking House </li>
                    <li> Sidney's Fibe </li>
                    <li> Tre </li>
                </ul> 
        </div>)
    }
}

export default NYCBobaTeaArticle