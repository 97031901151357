import React from "react";

class NYCSpeakeasiesArticle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div>
                <h1>NYC speakeasy Guide</h1>
                <li>Angel Share</li>
                <li>Attaboy</li>
                <li>Keys & Heels</li>
                <li>Little Branch</li>
                <li>Nothing Really Matters</li>
                <li>PDT</li>
                <li>Thyme Bar</li>
                <li>The Woo Woo NYC</li>
            </div>)
    }
}

export default NYCSpeakeasiesArticle