function Hotels() {
    return (
        <div>        
            <h1> Book hotel </h1>
            <div>
                <li> <a href="https://equinox-hotels.com/nyc/"> Equinox Hotel </a> </li>
                <li> <a href="https://hotel.muji.com/en/"> Muji Hotel </a> </li>                
            </div>
         </div>
    )
}

export default Hotels