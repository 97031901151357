import React from "react";

class NYCSpasArticle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {            
        return (            
            <div>
                <h1> NYC Spa Guide </h1>
                <li> Aura Wellness Spa </li>
        </div>)
    }
}

export default NYCSpasArticle