import React from "react";

class NYCPhoArticle extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {            
        return (            
            <div>
                <h1> NYC Pho Article </h1>
                <ul>                                        
                    <li>The Pho 2</li>
                </ul> 
        </div>)
    }
}

export default NYCPhoArticle