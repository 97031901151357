function TicketMarketplace() {
    return (
        <div>        
            <h1> Ticket marketplace </h1>           
            <div>
                <p style = { {textAlign: "center"} }> Venmo @micklebrain with comment of ticket name and quantity. Personally owned and authentic tickets. </p>
                <ul>
                    <li>                    
                        None available
                    </li>
                </ul>          
            </div>
            <div>
                <h2> Recent purchases </h2>
            </div>
         </div>
    )
}

export default TicketMarketplace