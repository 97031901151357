import React from "react";

class NYCRooftopBarsArticle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {            
        return (            
            <div>
                <h1> Rooftop bars </h1> 
                 <li> 230 5th </li>
                 <li> Castell Rooftop Lounge </li>
                 <li> Cloud Social Rooftop Bar </li>
                 <li> Magic Hour Rooftop Bar & Lounge </li>
                 <li> Monarch Rooftop </li>
                 <li> Mr.Purple </li>
                 <li> Refinery </li>
                 <li> Spyglass Rooftop Bar </li>
                 <li> Top of the Strand Rooftop Bar </li>
                 <li> The Roof </li>
                 <li> The Royalton Park Ave Rooftop Lounge & Pool </li>
                 <li> Up on 20 Rooftop Lounge </li>
                 <li> VERSA </li>
        </div>)
    }
}

export default NYCRooftopBarsArticle