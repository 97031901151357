import React from "react";

class Tester extends React.Component {

    render() {
        return (<div>
            <button class="button-30" role="button">Button 30</button>
        </div>)
    }
}

export default Tester