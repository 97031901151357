import React, { useState, useEffect, useSearchParams } from 'react';
import { useLocation } from 'react-router-dom'

const Laundry = (props) => {
    return (
        <div>
            <h1>Laundry</h1>
        </div>
    );
};

export default Laundry

