import React from "react";

class NYCWineBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div>
                <h1> NYC's best wine bars </h1>
                <li> 1986 Est. Wine Bar & Lounge </li>
                <li> Vin Sur Vingt Wine Bar </li>
            </div>)
    }
}

export default NYCWineBar