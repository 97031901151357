import React from "react";

class Bangkok extends React.Component {

    render() {
        return (<div class='container'>
            <h1>Bangkok the land of smiles guide</h1>
            <li>The Grand Palace</li>
            <li>The White Temple</li>
        </div>)
    }
}

export default Bangkok