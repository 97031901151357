import React from "react";

class NYCTour extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {            
        return (            
            <div>
                <h1> Let us plan your daytrip </h1>
                <h1> https://buy.stripe.com/test_7sIaFN3o0aDoe08144 </h1>                
        </div>)
    }
}

export default NYCTour